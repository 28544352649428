import React from "react";
import "../css/BorrowerSupportFund.css";
import { useScrollReveal } from "../hooks/useScrollReveal";
import Quiz from "../components/Quiz";
import borrowerSupportDoc from "../doc/borrowerSupport_docs/wniosek-o-udzielenie-wsparcia-promesy-lub-pozyczki-na-splate-zadluzenia-dla-kredytobiorcy-ktory-zaciagnal-kredyt-mieszkaniowy-i-znajduje-sie-w-trudnej-sytuacji-finansowej.pdf";

function BorrowerSupportFund() {
  useScrollReveal({ selector: "#borrowerSupportFund" });

  useScrollReveal({
    selector: ".whiteBgc, .imageBackground",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main id="borrowerSupportFund">
      <section className="imageSection">
        <div className="container">
          <div className="imageBackground">
            {" "}
            <header className="headerBg">
              <h1 className="heading">Fundusz Wsparcia Kredytobiorców</h1>
            </header>
          </div>
        </div>
      </section>

      <div className="container">
        <section id="aboutBorrowerSupportFund">
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>Czym jest Fundusz Wsparcia Kredytobiorców?</h2>
            </header>
            <p>
              Fundusz Wsparcia Kredytobiorców (dalej. FWK) utworzony został na
              mocy ustawy z&nbsp;dnia 9 października 2015r. o&nbsp;wsparciu
              kredytobiorców znajdujących się w&nbsp;trudnej sytuacji
              finansowej, którzy zaciągnęli kredyt mieszkaniowy.
            </p>
            <p>
              Od 1 stycznia 2020 r. wprowadzono zmiany do FWK nadanej przez
              Ustawę z&nbsp;dnia 4 lipca 2019 r. o&nbsp;zmianie ustawy
              o&nbsp;wsparciu kredytobiorców znajdujących się w&nbsp;trudnej
              sytuacji finansowej, którzy zaciągnęli kredyt mieszkaniowy oraz
              niektórych innych ustaw.
            </p>
            <p>
              29 lipca 2022&nbsp;r. wprowadzono kolejne zmiany do FWK
              wprowadzone Ustawą z&nbsp;7&nbsp;lipca 2022 roku
              o&nbsp;finansowaniu społecznościowym dla przedsięwzięć
              gospodarczych i&nbsp;pomocy kredytobiorcom.
            </p>
            <p>
              Od 15 maja 2024&nbsp;r. Fundusz funkcjonuje w&nbsp;nowej formule
              nadanej przez Ustawę z&nbsp;12 kwietnia 2024 roku o&nbsp;zmianie
              ustawy o&nbsp;wsparciu kredytobiorców, którzy zaciągnęli kredyt
              mieszkaniowy i&nbsp;znajdują się w&nbsp;trudnej sytuacji
              finansowej oraz ustawy o&nbsp;finasowaniu społecznościowym dla
              przedsięwzięć gospodarczych i&nbsp;pomocy kredytobiorcom
            </p>
            <p>Środki Funduszu pochodzą z&nbsp;wpłat kredytodawców.</p>
            <p>
              Wsparcie jest realizowane za pośrednictwem Banku Gospodarstwa
              Krajowego.
            </p>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>Jak działa Fundusz Wsparcia Kredytobiorców?</h2>
            </header>
            <p>
              W&nbsp;ramach FWK kredytobiorca może skorzystać z&nbsp;dwóch form
              pomocy – wparcia oraz pożyczki na spłatę zadłużenia pozostającego
              po zbyciu kredytowanej nieruchomości. O&nbsp;wsparcie jak
              i&nbsp;pożyczkę na spłatę zadłużenia mogą ubiegać się osoby
              posiadające kredyt, zarówno w&nbsp;PLN, jak i&nbsp;walucie obcej,
              niezależnie od oferty, w&nbsp;jakiej kredyt został udzielony.
            </p>
            <p>
              Od 2024r wsparcie może zostać przyznane na okres nie dłuższy niż
              40 miesięcy, w&nbsp;wysokości równowartości rat kapitałowych
              i&nbsp;odsetkowych kredytu mieszkaniowego, ale nie większej niż
              3000&nbsp;zł miesięcznie. Przy czym jeżeli kredytobiorca przed
              2024r otrzymywał wsparcie z&nbsp;FWK to łączny okres jego
              otrzymywania nie może przekroczyć 40 miesięcy.
            </p>
            <p>
              Pożyczka przeznaczana jest na spłatę zadłużenia pozostałego po
              sprzedaży kredytowanej nieruchomości, jeśli cena zbycia nie
              wystarczy na spłatę kredytu hipotecznego, przy czym jej kwota to
              różnica między wartością kredytu pozostałego do spłaty a&nbsp;ceną
              zbycia nieruchomości, jednak nie więcej niż 120&nbsp;000 zł.
              Kredytobiorca przed dokonaniem sprzedaży kredytowanej
              nieruchomości może wystąpić o&nbsp;przyrzeczenie udzielenia mu
              pożyczki na spłatę zadłużenia tzw. PROMESĘ. Promesa jest udzielana
              jeżeli kwota jaka ma być uzyskana ze sprzedaży kredytowanej
              nieruchomości, nie pokryłaby całego zobowiązania z&nbsp;tytułu
              umowy kredytu mieszkaniowego zaciągniętego na jej zakup. Promesa
              jest ważna 6 miesięcy. Warunkiem uzyskania promesy jest zawarcie
              przedwstępnej umowy sprzedaży kredytowanej nieruchomości
            </p>
            <p>
              Wniosek o&nbsp;każdą z&nbsp;form pomocy składa się w&nbsp;Banku,
              w&nbsp;którym posiada się kredyt mieszkaniowy.
            </p>
            <p>
              Pomoc jest zwrotna, ale nieoprocentowana. Po upływie
              24&nbsp;miesięcy od momentu zakończenia wypłaty ostatniej raty
              wsparcia lub pożyczki na spłatę zadłużenia, kredytobiorca
              zobowiązany jest do zwrotu otrzymanego wsparcia w&nbsp;200 ratach
              miesięcznych na rachunek na rachunek wskazany przez bank.
              W&nbsp;przypadku gdy Kredytobiorca, bez opóźnienia w&nbsp;spłacie,
              dokona spłaty 134&nbsp;rat lub ich równowartości, pozostała część
              rat zwrotu Wsparcia/Pożyczki na spłatę zadłużenia będzie podlegać
              umorzeniu o&nbsp;czym Kredytobiorca zostanie poinformowany przez
              bank a&nbsp;w&nbsp;przypadku kredytów, których spłata rozpoczęła
              się przed 1&nbsp;stycznia 2023&nbsp;r. przez Bank Gospodarstwa
              Krajowego.
            </p>
            <p>&nbsp;</p>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>Kto może liczyć na pomoc?</h2>
            </header>
            <p>
              Ustawa zakłada, że pomoc w&nbsp;postaci udzielenia
              wsparcia/pożyczki na spłatę zadłużenia/promesy&nbsp;będzie
              udzielana osobom, które spełniły jeden z&nbsp;warunków:
            </p>
            <ul>
              <li className="bottomLine">
                w&nbsp;dniu złożenia wniosku{" "}
                <span className="bold">
                  co najmniej jeden Kredytobiorca posiada status osoby
                  bezrobotnej
                </span>{" "}
                (wsparcie nie może być przyznane, jeżeli utrata zatrudnienia
                nastąpiła w&nbsp;wyniku rozwiązania umowy o&nbsp;pracę za
                wypowiedzeniem przez kredytobiorcę lub rozwiązania umowy
                o&nbsp;pracę bez wypowiedzenia, z&nbsp;winy pracownika),
                <span className="bold"> lub</span>
              </li>
              <li className="bottomLine">
                <span className="bold">
                  miesięczne koszty obsługi kredytu mieszkaniowego przekraczają
                  40% miesięcznych dochodów
                </span>{" "}
                osiąganych przez gospodarstwo domowe kredytobiorcy,{" "}
                <span className="bold">lub</span>
              </li>
              <li className="bottomLine">
                <span className="bold">
                  miesięczny dochód gospodarstwa domowego, pomniejszony
                  o&nbsp;miesięczne koszty obsługi kredytu mieszkaniowego, nie
                  przekracza
                </span>{" "}
                (wg stanu na dzień złożenia wniosku) kwoty:
                <ul className="dottedList">
                  <li>
                    w&nbsp;przypadku gospodarstwa jednoosobowego – 2525 zł
                  </li>
                  <li>
                    w&nbsp;przypadku gospodarstwa wieloosobowego – iloczyn
                    liczby osób w&nbsp;gospodarstwie domowym i&nbsp;kwoty
                    2057,50 zł
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>Jakie kredyty obejmuje ustawa?</h2>
            </header>
            <p>
              Pomoc może być udzielona kredytobiorcom, którzy zaciągnęli kredyt
              lub pożyczkę, zabezpieczone hipoteką, przeznaczone na
              sfinansowanie niezwiązanego z&nbsp;działalnością gospodarczą lub
              prowadzeniem gospodarstwa rolnego:
            </p>
            <ol className="numberList">
              <li>
                nabycia, budowy, przebudowy, rozbudowy lub nadbudowy domu
                jednorodzinnego albo lokalu mieszkalnego stanowiącego odrębną
                nieruchomość, oraz adaptacji pomieszczeń lub budynków
                niemieszkalnych na cele mieszkalne;
              </li>
              <li>
                nabycia spółdzielczego prawa do lokalu mieszkalnego lub prawa do
                domu jednorodzinnego w&nbsp;spółdzielni mieszkaniowej albo prawa
                odrębnej własności lokalu mieszkalnego w&nbsp;spółdzielni
                mieszkaniowej;
              </li>
              <li>
                remontu domu jednorodzinnego albo lokalu mieszkalnego,
                o&nbsp;których mowa w&nbsp;pkt 1 i&nbsp;2;
              </li>
              <li>
                nabycia działki budowlanej albo jej części pod budowę domu
                jednorodzinnego;
              </li>
              <li>
                nabycia działki rolnej albo jej części pod budowę domu
                jednorodzinnego, położonej w&nbsp;całości albo części na terenie
                przeznaczonym pod zabudowę mieszkaniową, zgodnie
                z&nbsp;miejscowym planem zagospodarowania przestrzennego,
                a&nbsp;w&nbsp;przypadku gdy teren, na którym położona jest
                działka rolna albo jej część, nie został objęty miejscowym
                planem zagospodarowania przestrzennego - nabycia działki albo
                jej części przeznaczonej pod budowę budynku mieszkalnego
                jednorodzinnego na podstawie decyzji o&nbsp;warunkach zabudowy
                i&nbsp;zagospodarowania terenu;
              </li>
              <li>
                udziału w&nbsp;kosztach budowy mieszkań przez towarzystwa
                budownictwa społecznego;
              </li>
              <li>
                innego celu związanego z&nbsp;zaspokajaniem potrzeb
                mieszkaniowych.
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              Kredytem mieszkaniowym w&nbsp;rozumieniu przepisów ustawy jest
              również:
            </p>
            <ol className="numberList">
              <li>
                zabezpieczony hipoteką kredyt udzielony na spłatę kredytu
                mieszkaniowego
              </li>
              <li>
                ta część kredytu udzielonego na spłatę różnych zobowiązań
                kredytowych, która jest przeznaczona na spłatę kredytu
                mieszkaniowego, jeżeli kredyt ten został zabezpieczony
                w&nbsp;sposób, o&nbsp;którym mowa w&nbsp;pkt 1.
              </li>
            </ol>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>
                Kiedy wsparcie/pożyczka na spłatę zadłużenia/promesa nie może
                zostać przyznana?
              </h2>
            </header>
            <ul>
              <li className="bottomLine">
                utrata zatrudnienia przez co najmniej jednego
                z&nbsp;kredytobiorców nastąpiła w&nbsp;wyniku rozwiązania umowy
                o&nbsp;pracę za wypowiedzeniem przez kredytobiorcę lub
                rozwiązania umowy o&nbsp;pracę bez wypowiedzenia z&nbsp;winy
                pracownika
              </li>
              <li className="bottomLine">
                umowa kredytu mieszkaniowego została skutecznie wypowiedziana
                przed złożeniem wniosku,
              </li>
              <li className="bottomLine">
                w&nbsp;dniu złożenia wniosku o&nbsp;wsparcie kredytobiorca:
                <ul class="dottedList">
                  <li>
                    jest właścicielem innego lokalu mieszkalnego lub domu
                    jednorodzinnego lub był nim w&nbsp;okresie 6 miesięcy przed
                    złożeniem wniosku,
                  </li>
                  <li>
                    posiadał inne spółdzielcze własnościowe prawo do lokalu
                    mieszkalnego, prawo do domu jednorodzinnego
                    w&nbsp;spółdzielni mieszkaniowej lub posiadał takie prawo
                    w&nbsp;okresie 6 miesięcy przed złożeniem wniosku,
                  </li>
                  <li>
                    posiada inne roszczenie o&nbsp;przeniesienie na niego prawa
                    własności lokalu mieszkalnego, domu jednorodzinnego,
                    spółdzielczego prawa do lokalu mieszkalnego lub domu
                    jednorodzinnego w&nbsp;spółdzielni mieszkaniowej lub
                    posiadał takie roszczenie w&nbsp;okresie 6 miesięcy przed
                    złożeniem wniosku.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </section>

        <section>
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>Dodatkowo wsparcie nie może być przyznane jeżeli:</h2>
            </header>
            <ul class="dottedList">
              <li>
                jeden z&nbsp;Wnioskodawców uzyskał wsparcie na zasadach
                określonych w&nbsp;ustawie, chyba że wsparcie nie jest już
                udzielane, a&nbsp;okres udzielonego wsparcia nie przekroczył 39
                miesięcy. W&nbsp;takim przypadku łączny okres wsparcia
                przyznanego Wnioskodawcom na spłatę kredytu mieszkaniowego,
                zabezpieczonego hipoteką ustanowioną na tym samym przedmiocie
                kredytowania, nie może przekroczyć 40 miesięcy
              </li>
              <li>
                co najmniej jednemu z&nbsp;kredytobiorców przysługuje
                świadczenie z&nbsp;tytułu utraty pracy wynikające
                z&nbsp;zawartej umowy ubezpieczenia spłaty kredytu,
                gwarantującej wypłatę świadczenia na wypadek utraty pracy
              </li>
            </ul>
          </div>
        </section>

        <section id="verificationBorrowersCapabilities">
          <div className="textDoubleMargin whiteBgc">
            <header>
              <h2>
                Weryfikacja możliwości uzyskania wsparcia przez Kredytobiorcę
              </h2>
            </header>
            <Quiz />
          </div>
        </section>

        <section id="howToGetSupport">
          <div className="whiteBgc text">
            <div className="text">
              <header>
                <h2>
                  Jak uzyskać wsparcie/pożyczkę na spłatę zadłużenia/promesę?
                </h2>
              </header>
              <ul className="dottedList">
                <li>
                  Skorzystaj z&nbsp;formatki udostępnionej na stronie
                  i&nbsp;jako załącznik dodaj wypełniony i&nbsp;podpisany
                  wniosek w&nbsp;formacie pdf
                </li>
              </ul>
            </div>

            <div className="text">
              <h3>
                Zapoznaj się z&nbsp;najważniejszymi informacjami dotyczącymi
                uzyskania wsparcia/ promesy/ pożyczki na spłatę zadłużenia
                z&nbsp;Funduszu Wsparcia Kredytobiorców
              </h3>

              <ol className="numberList">
                <li>
                  Otwórz link do wniosku
                  <br></br>
                  <a href="#">Wniosek dla Kredytobiorców</a>, którzy wnioskują
                  po raz pierwszy o&nbsp;wsparcie, pożyczkę na spłatę
                  zadłużenia, promesę lub jeżeli Kredytobiorca otrzymał wsparcie
                  udzielone przed 01.01.2020 i&nbsp;chce zawnioskować
                  o&nbsp;zmianę w&nbsp;zakresie kwoty i&nbsp;okresu udzielonego
                  wsparcia. <br></br>
                  <br></br>
                  <a href="#">Wniosek dla Kredytobiorców</a>, którzy otrzymali
                  wsparcie przed 01.01.2020r. i&nbsp;wnioskują o&nbsp;zmianę
                  w&nbsp;zakresie liczby rat zwracanego wsparcia tj. wydłużenie
                  łącznego okresu zwrotu wsparcia do 144 miesięcznych rat,
                  z&nbsp;uwzględnieniem liczby rat spłaconych, z&nbsp;możliwym
                  częściowym umorzeniem. Dotyczy umów o&nbsp;udzieleniu wsparcia
                  po zakończonej wypłacie wsparcia, będących w&nbsp;okresie
                  oczekiwania na rozpoczęcie spłaty wsparcia w&nbsp;ciągu dwóch
                  lat od wypłaty ostatniej raty wsparcia albo w&nbsp;trakcie
                  zwrotu wsparcia przez Kredytobiorcę.
                  <br></br>
                  <br></br>
                  Uwaga! Zawarcie aneksu nie jest możliwe jeżeli wsparcie
                  zostało już zwrócone oraz w&nbsp;przypadku zbycia przedmiotu
                  kredytowania lub jeżeli nastąpił zwrot nienależnego wsparcia
                  lub nienależnej pożyczki na spłatę zadłużenia.
                </li>
                <li>Zapisz wniosek na dysku.</li>
                <li>
                  Otwórz zapisany na dysku wniosek i&nbsp;edytuj
                  w&nbsp;programie Adobe Acrobat Reader, wprowadzając dane
                  w&nbsp;interaktywne pola (link do najnowszej wersji do
                  pobrania:
                  https://acrobat.adobe.com/pl/pl/acrobat/pdf-reader.html ).
                </li>
                <li>Zapisz tak wypełniony wniosek na dysku.</li>
                <li>
                  Otwórz zapisany wniosek i&nbsp;sprawdź, czy wpisane dane
                  zapisały się poprawnie.
                </li>
                <li>
                  Drukuj wniosek a&nbsp;następnie złóż podpis
                  w&nbsp;wyznaczonych miejscach.
                </li>
                <li>Zeskanuj lub zrób zdjęcie wniosku.</li>
                <li>
                  Wypełnij poniższy formularz na stronie i&nbsp;jako załącznik
                  dodaj wypełniony wniosek w&nbsp;formacie pdf.
                </li>
              </ol>
            </div>

            <div className="text">
              <h3 className="gold">
                Wypełnij formularz i&nbsp;prześlij wniosek
              </h3>
              <ol className="numberList">
                <li>
                  <a href={borrowerSupportDoc} className="gold underline">
                    Pobierz wniosek
                  </a>
                </li>
                <li>Wypełnij go elektronicznie.</li>
                <li>
                  Wydrukuj go i&nbsp;podpisz, a&nbsp;następnie zeskanuj lub zrób
                  zdjęcie.
                </li>
                <li>
                  Załącz w&nbsp;formularzu dostępnym pod adresem:
                  www.gnbbank.pl/kontakt
                </li>
              </ol>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default BorrowerSupportFund;
