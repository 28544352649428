import React from "react";
import "../css/Home.css";
import { Link } from "react-router-dom";
import { useScrollReveal } from "../hooks/useScrollReveal";

function Home() {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useScrollReveal({ selector: "#hero" });
  useScrollReveal({
    selector: ".headingText",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main id="home">
      <section id="hero">
        <div className="heroHeading">
          <header className="headingText">
            <h1>
              Informacje dla Klientów Getin Noble Bank S.A., którzy posiadają
              m.in. kredyty hipoteczne indeksowane lub denominowane do walut
              obcych
            </h1>
            <p>
              Witamy w portalu Getin Noble Bank S.A. w&nbsp;upadłości
              stworzonego w&nbsp;wyniku połączenia getinbank.pl/obsluga oraz
              gnbbank.pl. Znajdą Państwo tutaj wszystkie dotychczasowe
              funkcjonalności serwisu getinbank.pl/obsluga oraz informacje na
              temat postępowania upadłościowego.
            </p>
            <div>
              <Link to="/komunikat-dla-klientow-getin-noble-bank-sa">
                <button className="btnGold">Przeczytaj komunikat</button>
              </Link>
            </div>
          </header>
        </div>
      </section>

      <section id="services">
        <div className="container">
          <header className="sectionHeader">
            <h2>Najważniejsze&nbsp;informacje</h2>
          </header>
          <ul className="serviceCards">
            <li className="card rounded shadow">
              <h3>Tabele kursów i&nbsp;walut</h3>
              <Link to="/kursy-walut" className="gold">
                Więcej
              </Link>
            </li>
            <li className="card rounded shadow">
              <h3>Tabele kursów i&nbsp;walut udzielonych przez Noble Bank</h3>
              <Link to="/kursy-walut-noble-bank" className="gold">
                Więcej
              </Link>
            </li>
            <li className="card rounded shadow">
              <h3>Fundusz Wsparcia Kredytobiorców</h3>
              <Link to="/fundusz-wsparcia-kredytobiorcow" className="gold">
                Więcej
              </Link>
            </li>
            <li className="card rounded shadow">
              <h3>Fundusz Wsparcia Kredytobiorców - Ustawa Powodziowa</h3>
              <Link
                to="/fundusz-wsparcia-kredytobiorcow-ustawa-powodziowa"
                className="gold"
              >
                Więcej
              </Link>
            </li>
            <li className="card rounded shadow">
              <h3>Indeksy oraz stawki bazowe</h3>
              <Link to="/indeksy-oraz-stawki-bazowe" className="gold">
                Więcej
              </Link>
            </li>
            <li className="card rounded shadow">
              <h3>
                Informacje w&nbsp;zakresie produktów inwestycyjnych /
                ubezpieczeniowych / samochodowych
              </h3>
              <Link to="/centrum-dokumentow" className="gold">
                Więcej
              </Link>
            </li>
            <li className="card rounded shadow">
              <h3>Restrukturyzacja zadłużenia</h3>
              <Link to="/restrukturyzacja-zadluzenia" className="gold">
                Więcej
              </Link>
            </li>
            <li className="card rounded shadow">
              <h3>
                Informacje o&nbsp;wskaźnikach referencyjnych dotyczących stóp
                procentowych (BMR)
              </h3>
              <Link
                to="/informacje-o-wskaznikach-referencyjnych"
                className="gold"
              >
                Więcej
              </Link>
            </li>
            <li className="card rounded shadow">
              <h3>Ład korporacyjny</h3>
              <Link to="/lad-korporacyjny" className="gold">
                Więcej
              </Link>
            </li>
            <li className="card rounded shadow">
              <h3>Dane historyczne</h3>
              <Link to="/dane-historyczne" className="gold">
                Więcej
              </Link>
            </li>
            <li className="card rounded shadow">
              <h3>RODO</h3>
              <Link to="/rodo" className="gold">
                Więcej
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </main>
  );
}

export default Home;
