import React from "react";
import "../css/Documents.css";
import Accordion from "../components/Accordion";
import DocumentLink from "../components/DocumentLink";
import { useScrollReveal } from "../hooks/useScrollReveal";

import CorporateDocument_001 from "../doc/corporateGovernance/01_Informacja-o-realizacji-strategii-podatkowej-2020r.pdf";
import CorporateDocument_002 from "../doc/corporateGovernance/02_Informacja-o realizacji-strategii-podatkowej-2021r.pdf";
import CorporateDocument_003 from "../doc/corporateGovernance/03_Informacja-o-realizacji-strategii-podatkowej-2022r.pdf";
import CorporateDocument_004 from "../doc/corporateGovernance/04_Informacja-o-realizacji-strategii-podatkowej-od-01-01-2023r-do-19-07-2023r.pdf";
import CorporateDocument_005 from "../doc/corporateGovernance/05_Informacja-o-realizacji-strategii-podatkowej-od-20-07-2023r-do-31-12-2023r.pdf";

function CorporateGovernance() {
  function CorporateDocuments() {
    return (
      <>
        <DocumentLink
          href={CorporateDocument_001}
          linkText="Informacja o realizacji strategii podatkowej 2020 r."
        />
        <DocumentLink
          href={CorporateDocument_002}
          linkText="Informacja o realizacji strategii podatkowej 2021 r."
        />
        <DocumentLink
          href={CorporateDocument_003}
          linkText="Informacja o realizacji strategii podatkowej 2022 r."
        />
        <DocumentLink
          href={CorporateDocument_004}
          linkText="Informacja o realizacji strategii podatkowej od 01.01.2023 r. do 19.07.2023 r."
        />
        <DocumentLink
          href={CorporateDocument_005}
          linkText="Informacja o realizacji strategii podatkowej od 20.07.2023 r. do 31.12.2023 r."
        />
      </>
    );
  }
  useScrollReveal({ selector: "#historyData" });

  useScrollReveal({
    selector: ".container",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main>
      <section id="documents">
        <div className="container">
          <header>
            <h1 className="heading">Ład korporacyjny</h1>
          </header>{" "}
          <div className="whiteBgc">
            <h2>Informacja o realizacji strategii podatkowej</h2>
            <CorporateDocuments />
          </div>
        </div>
      </section>
    </main>
  );
}

export default CorporateGovernance;
